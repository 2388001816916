html {
  font-size: 100%;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-opensans);
  background: var(--background);
  color: var(--text-color);
}

.section.section-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.section.footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  text-align: right;
  padding-bottom: 10px;
  padding-right: 10px;
}

.visually-hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

h1, h2, h3 {
  margin-top: 0;
  line-height: 1.1;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 0.1875rem;
}
@media (min-width: 71.875em) {
  h1 {
    font-size: 1.75rem;
  }
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--text-color2);
}

a, a:visited, a:active {
  text-decoration: none;
}

.section.section-center.maintext {
  font-size: 5vw;
}

.section.footer.logo {
  font-size: 28pt;
  opacity: 0.75;
  letter-spacing: -0.08em;
}

:root {
  --toggle-bg-light: hsl(230, 22%, 74%);
  --toggle-bg-start: hsl(0, 0%, 100%);
  --toggle-bg-end: hsl(0, 0%, 100%);
  --toggle-light: hsl(230, 19%, 60%);
  --toggle-button-light: hsl(228, 46%, 96%);
  --dark-bg: hsl(210, 77%, 45%);
  --dark-top-bg: hsl(232, 19%, 15%);
  --dark-card: hsl(228, 28%, 20%);
  --dark-card-hover: hsl(228, 25%, 27%);
  --dark-text1: hsl(228, 34%, 66%);
  --dark-text2: hsl(0, 0%, 100%);
  --light-bg: hsl(0, 0%, 100%);
  --light-top-bg: hsl(225, 100%, 98%);
  --light-card: hsl(227, 47%, 96%);
  --light-card-hover: hsl(228, 33%, 91%);
  --light-text1: hsl(230, 12%, 44%);
  --light-text2: hsl(230, 17%, 14%);
  --background: var(--light-bg);
  --text-color: var(--light-text2);
  --text-color2: var(--light-text1);
  --toggle: var(--toggle-light);
  --toggle-bg: var(--toggle-bg-light);
  --toggle-button: var(--toggle-button-light);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: var(--dark-bg);
    --text-color: var(--dark-text2);
    --text-color2: var(--dark-text1);
    --toggle: var(--light-bg);
    --toggle-bg: linear-gradient(
      225deg,
      var(--toggle-bg-end) 0%,
      var(--toggle-bg-start) 98.02%
    );
    --toggle-button: var(--dark-bg);
  }
}
body.light {
  --background: var(--light-bg);
  --text-color: var(--light-text2);
  --text-color2: var(--light-text1);
  --toggle: var(--toggle-light);
  --toggle-bg: var(--toggle-bg-light);
  --toggle-button: var(--toggle-button-light);
}

body.dark {
  --background: var(--dark-bg);
  --text-color: var(--dark-text2);
  --text-color2: var(--dark-text1);
  --toggle: var(--light-bg);
  --toggle-bg: linear-gradient(
    225deg,
    var(--toggle-bg-end) 0%,
    var(--toggle-bg-start) 98.02%
  );
  --toggle-button: var(--dark-bg);
}

:root {
  --font-opensans: "Open Sans";
}

.container {
  padding: 0 1.5625rem;
  max-width: 69.375rem;
  margin: 0 auto 2.875rem;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.9375rem;
  margin-left: auto;
  margin-right: 0;
}
@media (min-width: 40em) {
  .header {
    justify-content: space-between;
    align-items: center;
  }
}
.header__title {
  width: 100%;
}
@media (min-width: 40em) {
  .header__title {
    width: auto;
  }
}
.header__subtitle {
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--text-color2);
}

.toggle {
  display: grid;
  grid-template-columns: 1fr 1rem;
  border: none;
  margin: 0;
}
.toggle__wrapper {
  position: relative;
  height: 1.5rem;
}
.toggle input[type=radio] {
  margin: 0 -0.125rem 0 -0.125rem;
  opacity: 0;
  width: 1.5rem;
  height: 1.5rem;
}
.toggle input[type=radio]:focus ~ .toggle__button {
  border: 2px solid white;
}
.toggle__background {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  border-radius: 0.75rem;
  background: var(--toggle-bg);
  pointer-events: none;
}
.toggle__button {
  position: absolute;
  left: 0.1875rem;
  top: 0.1875rem;
  right: 100%;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background-color: var(--toggle-button);
  transition: all 150ms ease-in-out;
}
.toggle #light:checked ~ .toggle__button {
  left: calc(100% - 21px);
  right: 3px;
}
.toggle #system:checked ~ .toggle__button {
  left: 50%;
  right: auto;
  transform: translate(-50%);
}